import React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Hero from '../../components/Hero'
import { StaticImage } from 'gatsby-plugin-image'

const DownloadPageContent = () => (
  <div className="main">
    <section className="download__section">
    <div className="download__inner download-thanks__inner">
      <div className="download-thanks__img-wrapper">
        {/* <StaticImage className="download-list__img" src="../../../static/image/downloads/outgoing_mail.svg" placeholder="none" alt="メール送信画像" /> */}
        <img className="download-thanks__img" src="/image/downloads/outgoing_mail.svg" alt="メール送信画像" />
      </div>
      <div className="download-thanks__text">
        <p>
          ご請求ありがとうございました。<br/>
          ご入力いただいたメールアドレスに資料をダウンロードするリンクを送信致しました。<br/>
          {/* その他の資料については、ダウンロード資料一覧からご確認ください。<br/>
          その他のお問い合わせは、お問合せフォームからお問合せ内容を記入して送信してください。 */}
        </p>
      </div>
      <div className="download-thanks__button-wrapper">
        <a className="btn-purple" href="/download/">資料一覧ページへ</a>
        <a className="btn-pink" href="https://blog.mobalab.net/inquiry/">お問い合わせフォームへ</a>
      </div>
      </div>
    </section>
  </div>
)

const DownloadPage = (props) => (
  <Layout
    title="資料請求ありがとうございました | 株式会社もばらぶ"
    description=""
    pathName={props.location.pathname}
  >
    <Header />
    <DownloadPageContent />
  </Layout>
)
export default DownloadPage